'use strict';

var React = require("react");
var Layout = require("./Layout.js");
var Timeline = require("./Timeline.js");
var ReactHelmet = require("react-helmet");

function ArchivesPage(Props) {
  var data = Props.data;
  var posts = data.allMarkdownRemark.edges;
  var last = /* record */[/* contents */undefined];
  return React.createElement(Layout.make, {
              children: null
            }, React.createElement(ReactHelmet.Helmet, {
                  title: "Archives"
                }), React.createElement(Timeline.List.make, {
                  children: posts.map((function (post) {
                          var dateISOString = post.node.frontmatter.date;
                          var year = dateISOString.substring(0, dateISOString.indexOf("-"));
                          if (last[0] !== year) {
                            last[0] = year;
                            return React.createElement(React.Fragment, {
                                        children: null,
                                        key: year
                                      }, React.createElement(Timeline.RowMajor.make, {
                                            title: year,
                                            iconName: "calendar"
                                          }), React.createElement(Timeline.Row.make, {
                                            post: post
                                          }));
                          } else {
                            return React.createElement(Timeline.Row.make, {
                                        post: post,
                                        key: post.node.id
                                      });
                          }
                        }))
                }));
}

var make = ArchivesPage;

exports.make = make;
/* react Not a pure module */
