'use strict';

var React = require("react");
var Article = require("./Article.js");
var ReactFontawesome = require("react-fontawesome");

function Timeline$List(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "timeline timeline-wrap"
            }, children);
}

var List = {
  make: Timeline$List
};

function Timeline$Row(Props) {
  var post = Props.post;
  var node = post.node;
  var slug = node.fields.slug;
  var frontmatter = node.frontmatter;
  return React.createElement("div", {
              className: "timeline-row"
            }, React.createElement("span", {
                  className: "node"
                }), React.createElement("div", {
                  className: "content"
                }, React.createElement(Article.Title.make, {
                      className: "timeline-article-title",
                      slug: slug,
                      title: frontmatter.title
                    }), React.createElement(Article.Meta.make, {
                      slug: slug,
                      frontmatter: frontmatter
                    })));
}

var Row = {
  make: Timeline$Row
};

function Timeline$RowMajor(Props) {
  var title = Props.title;
  var iconName = Props.iconName;
  return React.createElement("div", {
              className: "timeline-row timeline-row-major"
            }, React.createElement("span", {
                  className: "node"
                }, React.createElement(ReactFontawesome, {
                      name: iconName
                    })), React.createElement("h1", {
                  className: "title"
                }, title));
}

var RowMajor = {
  make: Timeline$RowMajor
};

exports.List = List;
exports.Row = Row;
exports.RowMajor = RowMajor;
/* react Not a pure module */
